<template>
    <div class="downloads">
        <div class="downloads-item">
            <div class="downloads-qrcode">
                <img
                    class="downloads-qrcode__img"
                    src="@/assets/images/container/products/qrcode.png"
                />
                <div class="downloads-qrcode__msg">
                    扫二维码下载APP
                </div>
            </div>
        </div>

        <div class="downloads-item">
            <div class="downloads-apple downloads-app">
                <img src="@/assets/images/container/downloads/apple.png" />

                <div class="downloads-msg">
                    <div class="downloads-msg__label">
                        <div>版 本：</div>
                        <div>更新日期：</div>
                    </div>
                    <div class="downloads-msg__value">
                        <div class="downloads-msg__version">
                            3.2.6
                        </div>
                        <div class="downloads-msg__date">1.14</div>
                    </div>
                </div>

                <a
                    target="_blank"
                    href="https://itunes.apple.com/cn/app/qq/id1493475758"
                >
                    <div class="downloads-btn"></div>
                </a>
            </div>
        </div>

        <div class="downloads-item">
            <div class="downloads-android downloads-app">
                <img src="@/assets/images/container/downloads/android.png" />

                <div class="downloads-msg">
                    <div class="downloads-msg__label">
                        <div>版 本：</div>
                        <div>更新日期：</div>
                    </div>
                    <div class="downloads-msg__value">
                        <div class="downloads-msg__version">
                            3.2.6
                        </div>
                        <div class="downloads-msg__date">1.14</div>
                    </div>
                </div>

                <a
                    href="https://a.app.qq.com/o/simple.jsp?pkgname=com.kimoo.duduro"
                    target="_blank"
                >
                    <div class="downloads-btn"></div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMobile: this.$userAgent.isMobile
        };
    },

    mounted() {}
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
    .banner {
        width: 100%;
        margin-bottom: calc(100vw / 1920 * 50) !important;
    }

    .downloads {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(100vw / 1920 * 150);

        &-item {
            > div {
                width: calc(100vw / 1920 * 240);
                height: calc(100vw / 1920 * 288);
                background-image: url("../assets/images/container/products/download_bg.png");
                background-size: 100% 100%;
                background-position: 0 0;
                color: #383838;
                text-align: center;
                position: relative;
            }

            + .downloads-item {
                margin-left: calc(100vw / 1920 * 45);
            }
        }

        &-qrcode {
            font-size: calc(100vw / 1920 * 24);
            padding-top: calc(100vw / 1920 * 20);
            line-height: 1;

            &__img {
                width: calc(100vw / 1920 * 190);
                height: calc(100vw / 1920 * 190);
                margin-bottom: calc(100vw / 1920 * 16);
            }
        }

        &-apple {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_2.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_1.png");
                }
            }
        }

        &-android {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_3.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_4.png");
                }
            }
        }

        &-app {
            font-size: calc(100vw / 1920 * 20);

            img {
                width: calc(100vw / 1920 * 241);
                height: calc(100vw / 1920 * 288);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-btn {
            width: calc(100vw / 1920 * 208);
            height: calc(100vw / 1920 * 64);
            background-size: 100% 100%;
            background-position: 0 0;
            margin: calc(100vw / 1920 * 15) auto 0;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }

        &-msg {
            display: flex;
            justify-content: center;
            padding-top: calc(100vw / 1920 * 149);
            line-height: 1.25;
            z-index: 1;
            position: relative;

            &__label {
                text-align: right;
            }

            &__value {
                text-align: left;
            }
        }
    }

    .other-products {
        text-align: center;

        &__title {
            width: calc(100vw / 1920 * 336);
            margin-bottom: calc(100vw / 1920 * 35);
        }

        &-list {
            display: flex;
            justify-content: center;
            margin-bottom: calc(100vw / 1920 * 284);
        }

        &-item {
            width: calc(100vw / 1920 * 176);
            height: calc(100vw / 1920 * 250);
            background-image: url("../assets/images/container/downloads/pic.png");
            background-size: 100% 100%;
            background-position: 0 0;
            position: relative;

            img {
                width: 100%;
            }

            + .other-products-item {
                margin-left: calc(100vw / 1920 * 60);
            }
        }

        &__qrcode {
            width: calc(100vw / 1920 * 144) !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
};

@media (max-width: 1024px) {
    .banner {
        margin-bottom: calc(100vw / 1080 * 50) !important;
    }

    .container {
        padding: 0 !important;
    }

    .downloads {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: calc(100vw / 1920 * 132);

        &-item {
            > div {
                width: calc(100vw / 1080 * 467);
                height: calc(100vw / 1080 * 558);
                background-image: url("../assets/images/container/products/download_bg.png");
                background-size: 100% 100%;
                background-position: 0 0;
                color: #383838;
                text-align: center;
                position: relative;
            }

            + .downloads-item {
                margin-left: calc(100vw / 1080 * 48);
            }

            &:nth-child(1) {
                width: 100%;
                margin-bottom: calc(100vw / 1080 * 42);
            }
        }

        &-qrcode {
            font-size: calc(100vw / 1080 * 42);
            padding-top: calc(100vw / 1080 * 38);
            margin: 0 auto;

            &__img {
                width: calc(100vw / 1080 * 351);
                height: calc(100vw / 1080 * 351);
                margin-bottom: calc(100vw / 1080 * 28);
            }
        }

        &-apple {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_2.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_1.png");
                }
            }
        }

        &-android {
            .downloads-btn {
                background-image: url("../assets/images/container/downloads/btn_3.png");

                &:hover {
                    background-image: url("../assets/images/container/downloads/btn_4.png");
                }
            }
        }

        &-app {
            font-size: calc(100vw / 1080 * 36);

            img {
                width: calc(100vw / 1080 * 450);
                height: calc(100vw / 1080 * 540);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-btn {
            width: calc(100vw / 1080 * 390);
            height: calc(100vw / 1080 * 120);
            background-size: 100% 100%;
            background-position: 0 0;
            margin: calc(100vw / 1080 * 29) auto 0;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }

        &-msg {
            display: flex;
            justify-content: center;
            padding-top: calc(100vw / 1080 * 271);
            line-height: 1.25;

            &__label {
                text-align: right;
            }

            &__value {
                text-align: left;
            }
        }
    }

    .other-products {
        text-align: center;
        margin-top: calc(100vw / 1080 * 160);

        &__title {
            width: calc(100vw / 1080 * 630);
            margin-bottom: calc(100vw / 1080 * 80);
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: calc(100vw / 1080 * 192);
        }

        &-item {
            width: calc(100vw / 1080 * 397);
            height: calc(100vw / 1080 * 551);
            background-image: url("../assets/images/container/downloads/pic.png");
            background-size: 100% 100%;
            background-position: 0 0;
            position: relative;
            margin-bottom: calc(100vw / 1080 * 60);

            img {
                width: 100%;
            }

            + .other-products-item {
                margin-left: calc(100vw / 1080 * 70);
            }

            &:nth-child(2n + 1) {
                margin-left: 0;
            }
        }

        &__qrcode {
            width: calc(100vw / 1080 * 351) !important;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
